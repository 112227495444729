import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-aboutus',
  templateUrl: './aboutus.component.html',
  styleUrls: ['./aboutus.component.scss']
})
export class AboutusComponent implements OnInit {
  isVendor:boolean = false;
  constructor() {
    if (window.sessionStorage.getItem('vname') != null && window.sessionStorage.getItem('vname') != '' &&
      window.sessionStorage.getItem('vname') != undefined) {
        this.isVendor = true;
      }
  }

  ngOnInit() {
  }
  viewCategories(){
    window.location.href='/home';
  }
}
