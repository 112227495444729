import { Component, OnInit } from '@angular/core';
import { LandChecksService } from '../landchecks.service';
import { FormGroup, Validators, FormControl, AbstractControl } from '@angular/forms';
import { HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-resetnewpassword',
  templateUrl: './resetnewpassword.component.html',
  styleUrls: ['./resetnewpassword.component.scss']
})
export class ResetNewpasswordComponent implements OnInit {

  error: boolean = false;
  success: boolean = false;
  mismatch: boolean = false;
  message: string = '';
  userId:string;

  ResetNewPasswordForm = new FormGroup({
    NewPassword: new FormControl('', Validators.required),
    ConfirmNewPassword: new FormControl('', Validators.required)
  }, {
    validators: this.passwordMatchValidator
  });

  constructor(private landChecksService: LandChecksService) { }

  ngOnInit() {
    const url = window.location.href;
    
    var values = url.split('.');
    
    //user id is last but one value in the string separated by dot .
    this.userId = values[values.length-2];
    
  }
  passwordMatchValidator(c: AbstractControl): { invalid: boolean } {
    if (c.get('NewPassword').value !== c.get('ConfirmNewPassword').value) {
      return { invalid: true }
    }
  }
  resetNewPassword() {
    this.landChecksService.showLoader(true);
    let data = {
      CurrentPassword: this.ResetNewPasswordForm.controls['NewPassword'].value,
      NewPassword: this.ResetNewPasswordForm.controls['ConfirmNewPassword'].value
    }
    this.landChecksService.emailResetPassword(this.userId, data).subscribe((data: any) => {
      if (data && data != null && data.IsSuccess) {
        // this.ResetNewPasswordForm = new FormGroup({
        //   NewPassword: new FormControl('', Validators.required),
        //   ConfirmNewPassword: new FormControl('', Validators.required),
        // });
        // window.sessionStorage.clear();
        this.success = true;
        this.message = "Password reset successfully. Please login again";
      }
      else {
        this.error = true;
        this.message = "Password reset failed !!";
      }
      this.landChecksService.showLoader(false);
    });
  }

}