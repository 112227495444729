import { Component, OnInit } from '@angular/core';
import { LandChecksService } from '../landchecks.service';
import { FormGroup, Validators, FormControl } from '@angular/forms';

@Component({
  selector: 'app-resetpassword',
  templateUrl: './resetpassword.component.html',
  styleUrls: ['./resetpassword.component.scss']
})
export class ResetpasswordComponent implements OnInit {

  error: boolean = false;
  success: boolean = false;
  message: string = '';

  ResetPasswordForm = new FormGroup({
    CurrentPassword: new FormControl('', Validators.required),
    NewPassword: new FormControl('', Validators.required),
  });

  constructor(private landChecksService: LandChecksService) { }

  ngOnInit() {
  }

  resetPassword() {
    this.landChecksService.showLoader(true);
    let data = {
      CurrentPassword: this.ResetPasswordForm.controls['CurrentPassword'].value,
      NewPassword: this.ResetPasswordForm.controls['NewPassword'].value
    }
    let userId = window.sessionStorage.getItem("userId");
    this.landChecksService.resetPassword(userId, data).subscribe((data: any) => {
      if (data && data != null && data.IsSuccess) {
        this.ResetPasswordForm = new FormGroup({
          CurrentPassword: new FormControl('', Validators.required),
          NewPassword: new FormControl('', Validators.required),
        });
        window.sessionStorage.clear();
        this.success = true;
        this.message = "Password reset successfully. Please login again";
      }
      else {
        this.error = true;
        this.message = "Password reset failed !!";
      }
      this.landChecksService.showLoader(false);
    });
  }

}
