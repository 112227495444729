import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {

  isVendor:boolean = false;
  constructor() {
    if (window.sessionStorage.getItem('vname') != null && window.sessionStorage.getItem('vname') != '' &&
      window.sessionStorage.getItem('vname') != undefined) {
        this.isVendor = true;
      }
  }
  ngOnInit() {
  }
  viewCategories(){
    window.location.href='/home';
  }
}
