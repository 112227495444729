import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { LandChecksService } from '../landchecks.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.scss']
})
export class ContactusComponent implements OnInit {

  @Input() isVendor:boolean;
  error: boolean = false;
  success: boolean = false;
  message: string = '';
  todayDate : string = new Date().toISOString(); 
  isUserLogin :boolean = false;

  ContactForm = new FormGroup({
    FirstName: new FormControl('', Validators.required),
    LastName: new FormControl('', Validators.required),
    Organization: new FormControl('', Validators.required),
    Email: new FormControl('', Validators.required),
    PhoneNumber: new FormControl('', Validators.required),
    Comments: new FormControl('', Validators.required),
    contactRecaptcha: new FormControl('', Validators.required)
  });

  constructor(private LandChecksService: LandChecksService) { 
    if(window.sessionStorage.getItem('uname')){
      this.isUserLogin = true;
    }
  }

  ngOnInit() {
  }
  submitContact() {
    this.success = false;
    this.error = false;
    this.message = "";
    let user =
    {
      Id: 0,
      FirstName: this.ContactForm.controls['FirstName'].value,
      LastName: this.ContactForm.controls['LastName'].value,
      OrgName: this.ContactForm.controls['Organization'].value,
      Email: this.ContactForm.controls['Email'].value,
      Phone: this.ContactForm.controls['PhoneNumber'].value,
      Comments: this.ContactForm.controls['Comments'].value,
      CreatedDate: this.todayDate
    }
    this.LandChecksService.showLoader(true);
    this.LandChecksService.submitContact(user).subscribe((data: any) => {
      if (data && data != null) {
        var islogin = this.isUserLogin;
        this.LandChecksService.showLoader(false);
        Swal.fire({
          icon: 'success',
          text: "Your request has been sent. We will respond to your queries."
        }).then(function() {
          if(islogin){
            window.location.href = '/home';
          }else{
            window.location.href = '/landing';
          }
        })
      }
      else {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: "Something went wrong"
        })
      }
    });
  }
  viewCategories(){
    window.location.href='/home';
  }
}
