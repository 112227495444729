import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { LandChecksService } from '../landchecks.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Pipe, PipeTransform } from '@angular/core';
import {DomSanitizer} from "@angular/platform-browser";

declare const initdashboard: any;
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  @Input() isVendor:boolean;
  @Input() dashboardContent: string;
  @Input() isStateSelected:boolean;
  @Input() searchType: string;
  isUserLogin :boolean = false;
  isVCDUser :boolean = false;
  dashboardId :string;
  contentObjectString : string;
  @Output() initDashboard = new EventEmitter();
  constructor(private LandChecksService: LandChecksService, private router: Router, private route: ActivatedRoute) { 
    if(window.sessionStorage.getItem('uname')){
      this.isUserLogin = true;
      
    }
  }
  ngOnInit() {
  }
  loadDashboard(id){
    this.initDashboard.emit(id);
  }
  checkVCDUser(){
    console.log('incheck vcd user');
    if((window.sessionStorage.getItem('uname') == "suresh@gvvit.org") || (window.sessionStorage.getItem('uname') == "pradjob@gmail.com")
      || (window.sessionStorage.getItem('uname') == "dravula@gmail.com") || (window.sessionStorage.getItem('uname') == "seanmerfeld@venturedcg.com"))
    {
        this.isVCDUser = true;
        console.log('vcd user:');
        console.log(this.isVCDUser);
    }
    return this.isVCDUser;
  }
}
@Pipe({ name: 'safeHtml' })
export class SafeHtmlPipe implements PipeTransform {
  constructor(private sanitizer:DomSanitizer){}
  transform(html) {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }
}
