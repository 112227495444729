import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MatDialogRef, MatDialog } from '@angular/material';
import { LoaderComponent } from './loader/loader.component';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LandChecksService {

  loadingSpinnerObj: MatDialogRef<LoaderComponent>;

  private baseUrl = environment.landChecksApiBaseUrl;


  constructor(private http: HttpClient, public dialog: MatDialog) {

  }

  getHttpOptions() {
    let httpOptions = {
      headers: new HttpHeaders({
        "Authorization": `Bearer ${window.sessionStorage.getItem('userToken')}`,
        'Content-Type': 'application/json'
      })
    };
    return httpOptions;
  }

  getFavorites(userId: any): Observable<any> {
    let method = `${this.baseUrl}users/${userId}/favorites`;
    return this.http.get(`${method}`, this.getHttpOptions());
  }

  getTransactions(userId: number): Observable<any> {
    let method = `${this.baseUrl}users/${userId}/transactions`;
    return this.http.get(`${method}`, this.getHttpOptions());
  }

  insertFavorites(data: any): Observable<Object> {
    let method = `${this.baseUrl}users/${data.UserId}/favorites`;
    return this.http.post(`${method}`, data, this.getHttpOptions());
  }
  
  removeFavorite(data: any): Observable<Object>{
    let method = `${this.baseUrl}users/${data.UserId}/deletefavorites/${data.favId}`;
    return this.http.post(`${method}`, data, this.getHttpOptions());
  }
  insertTransaction(data: any): Observable<Object> {
    let method = `${this.baseUrl}users/${data.UserId}/transactions`;
    return this.http.post(`${method}`, data, this.getHttpOptions());
  }

  getCategorySummary(data: any): Observable<any> {
    let method = `${this.baseUrl}landchecks/categorysummary`;
    return this.http.post(`${method}`, data, this.getHttpOptions());
  }

  getCategoryListing(data: any): Observable<any> {
    let method = `${this.baseUrl}landchecks/listing/${data.CategoryId}`;
    return this.http.post(`${method}`, data, this.getHttpOptions());
  }

  userAuthentication(email: string, password: string): Observable<any> {
    let credentials = 'username=' + email + '&password=' + password + '&grant_type=password';
    var reqHeader = new HttpHeaders({ 'Content-Type': 'application/x-www-urlencoded' });
    return this.http.post<any>(this.baseUrl + 'token', encodeURI(credentials), { headers: reqHeader });
  }

  getRequestData(reqID: string): Observable<any> {
    let method = `${this.baseUrl}users/GetRequestData/${reqID}`;
    return this.http.get(method);
  }

  verifyForgotPasswordEmail(email: string): Observable<any> {
    let method = `${this.baseUrl}users/email`;
    return this.http.post<any>(method, `\"${email}\"`, this.getHttpOptions());
  }

  getVendorKey(): Observable<any> {
    return this.http.get("../assets/vendor.key.json");
  }

  validateVendorKey(apiKey: string): Observable<any> {
    debugger;
    let method = `${this.baseUrl}users/vendor/${apiKey}`;
    return this.http.get(`${method}`, this.getHttpOptions());
  }

  showLoader(showSpinner: boolean) {
    if (showSpinner) {
      setTimeout(() => {
        this.loadingSpinnerObj = this.dialog.open(LoaderComponent, {
          disableClose: true,
          panelClass: 'loader-spinner',
          data: {}
        });
      }, 0);
      return;
    }
    setTimeout(() => {
      this.loadingSpinnerObj.close();
    }, 0);
  }

  getCategories(): Observable<any> {
    let method = `${this.baseUrl}users/category`;
    return this.http.get(`${method}`, this.getHttpOptions());
  }

  resetPassword(userId, data: any): Observable<any> {
    let method = `${this.baseUrl}users/${userId}/reset`;
    return this.http.post(`${method}`, data, this.getHttpOptions());
  }

  emailResetPassword(userId, data: any): Observable<any> {
    let method = `${this.baseUrl}users/${userId}/emailreset`;
    return this.http.post(`${method}`, data, this.getHttpOptions());
  }

  register(data: any): Observable<any> {
    let method = `${this.baseUrl}users`;
    debugger;
    return this.http.post(`${method}`, data, this.getHttpOptions());
  }
  
  submitContact(data: any): Observable<any> {
    let method = `${this.baseUrl}users/AddEnquiry`;
    return this.http.post(`${method}`, data, this.getHttpOptions());
  }
  getRequestId(data: any): Observable<Object> {
    let method = `${this.baseUrl}users/GetRequestID?apiKey=${data.apiKey}&lat=${data.lat}&lng=${data.lng}&address=${data.address}`;
    return this.http.post(`${method}`, data, this.getHttpOptions());
  }
  
  activateUserLink(data: any): Observable<any> {
    let method = `${this.baseUrl}users/${data.userId}/userActivate`;
    return this.http.post(`${method}`, data, this.getHttpOptions());
  }
}