import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { LandChecksService } from '../landchecks.service';
declare var grecaptcha: any;


@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit {

  
  isVendor: boolean = false;
  RegistrationForm = new FormGroup({
    FirstName: new FormControl('', Validators.required),
    LastName: new FormControl('', Validators.required),
    myRecaptcha: new FormControl('', Validators.required),
    Email: new FormControl('', [Validators.required, Validators.email]),
    Password: new FormControl('', [Validators.required, Validators.minLength(6)]),
    ConfirmPassword: new FormControl('', Validators.required)
  }, {
    validators: this.passwordConfirming
  });

  error: boolean = false;
  success: boolean = false;
  message: string = '';

  
  constructor(private landChecksService: LandChecksService) { 
    window.sessionStorage.clear();
    this.isVendor = false;
  }

  ngOnInit() {
    
  }
  getResponceCapcha(captchaResponse: string) {
    this.verifyCaptcha(captchaResponse);
  }
  
  verifyCaptcha(captchaResponse: string) {
    //alert(captchaResponse);
    
      this.success= true;
    
  
  }
  
  

  passwordConfirming(c: AbstractControl): { invalid: boolean } {
    if (c.get('Password').value !== c.get('ConfirmPassword').value) {
      return { invalid: true };
    }
  }

  register() {
    this.success = false;
    this.error = false;
    this.message = "";
    let user =
    {
      FirstName: this.RegistrationForm.controls['FirstName'].value,
      LastName: this.RegistrationForm.controls['LastName'].value,
      Email: this.RegistrationForm.controls['Email'].value,
      Password: this.RegistrationForm.controls['Password'].value
    }

    this.landChecksService.register(user).subscribe((data: any) => {
      if (data && data != null) {
        if (data.IsSuccess) {
          this.success = true;
          this.message = "Activation link sent to your email. Please check your inbox and activate to register";
        }
        else if (data.IsEmailAlreadyRegistered) {
          this.error = true;
          this.message = "User already registered with us. Please use different Email.";
        }
      }
      else {
        this.error = true;
        this.message = "Email not registered with us.";
      }
    });
  }

}
