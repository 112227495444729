import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { LandChecksService } from '../landchecks.service';

@Component({
  selector: 'app-forgotpassword',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['./forgotpassword.component.scss']
})
export class ForgotpasswordComponent implements OnInit {

  error: boolean = false;
  success: boolean = false;
  message: string = '';

  ForgotPasswordForm = new FormGroup({
    UserName: new FormControl('', Validators.required),
  });

  constructor(private landChecksService: LandChecksService) { }

  ngOnInit() {
  }

  verifyForgotPasswordEmail() {
    const user = this.ForgotPasswordForm.controls['UserName'].value;
    this.landChecksService.verifyForgotPasswordEmail(user).subscribe((data: any) => {
      if (data && data != null) {
        this.success = true;
        this.message = "Password reset link sent to your email. Please check your inbox!!!";
      }
      else {
        this.error = true;
        this.message = "Email not registered with us.";
      }
    });
  }

}
