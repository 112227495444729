import { Component, NgZone } from '@angular/core';
import { LandChecksService } from './landchecks.service';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpParams } from '@angular/common/http';
import { NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {
  throughVendor: boolean = false;
  constructor(private router: Router, private landChecksService: LandChecksService, private route: ActivatedRoute) {
    let gtag: Function;
    let key = this.getParamValueQueryString('apikey');
    let lat = this.getParamValueQueryString('lat');
    let lng = this.getParamValueQueryString('lng');
    let loc = this.getParamValueQueryString('loc');
    let requestID = this.getParamValueQueryString("RequestID");
    let currentUrl = window.location.pathname+window.location.search;
    if(requestID){
      this.throughVendor = true;
    }
    else{
    }

    this.router.events.subscribe(event => {
      if(event instanceof NavigationEnd){
          debugger;
          gtag('config', '162009032', 
                {
                  'page_path': event.urlAfterRedirects
                }
               );
       }
    });

    if (key != undefined && key != '' && key != null && requestID != undefined && requestID !='' && requestID != null)
    {
      this.landChecksService.validateVendorKey(key).subscribe(vendor => {
        if (vendor && vendor != null) {
          this.landChecksService.userAuthentication('vendor.user@mailinator.com', 'admin').subscribe((data: any) => {
            window.sessionStorage.setItem('userToken', data.access_token);
            window.sessionStorage.setItem('userId', data.userId);
            
            // debugger;
            // if(window.location.pathname.indexOf("landing") > -1){
            //   window.location.href =currentUrl;
            // }
            
           // this.router.navigate([currentUrl]);
          });
          this.landChecksService.getRequestData(requestID).subscribe(reqData => {
            if (reqData && reqData != null) {
              if (reqData.isValid){
                lat = reqData.Latitude;
                lng = reqData.Longitude;
                loc = reqData.Address;
                debugger;
                window.sessionStorage.setItem('lng', lng);
                window.sessionStorage.setItem('lat', lat);
                window.sessionStorage.setItem('loc', loc);
                window.sessionStorage.setItem('apiKey', key);
                window.sessionStorage.setItem('vrequestID', requestID);
                window.sessionStorage.setItem('vname', vendor.Name);
                window.sessionStorage.setItem('isvendor', "true");
                window.sessionStorage.setItem('vendortype', vendor.VendorType);
                window.location.href = '/home';
              }
              else
              {
                // if request is invalid
                this.router.navigate(['/landing']);
              }
            }
            else {
              this.router.navigate(['/landing']);
            }
          });
        }else{
          this.router.navigate(['/login']);
        }
      });
      this.landChecksService.getRequestData(requestID).subscribe(reqData => {
        if (reqData && reqData != null) {
          
          if (reqData.isValid){
            lat = reqData.Latitude;
                  lng = reqData.Longitude;
                  loc = reqData.Address;
                  let vname = reqData.VendorName;
            debugger;
            this.landChecksService.userAuthentication('vendor.user@mailinator.com', 'admin').subscribe((data: any) => {
              window.sessionStorage.setItem('userToken', data.access_token);
              window.sessionStorage.setItem('userId', data.userId);
              window.sessionStorage.setItem('apiKey', key);

              window.sessionStorage.setItem('lng', lng);
              window.sessionStorage.setItem('lat', lat);
              window.sessionStorage.setItem('loc', loc);
              window.sessionStorage.setItem('vrequestID', requestID);

              window.sessionStorage.setItem('vname', vname);
              window.sessionStorage.setItem('isvendor', "true");
              
              //debugger;
              //if(window.location.pathname.indexOf("login") > -1){
              //  window.location.href =currentUrl;
              //}
            //this.router.navigate(['/home']);
              window.location.href = '/home';
            });
          }
          else
          {
            // if request is invalid
            this.router.navigate(['/landing']);
          }
        }
        else {
          this.router.navigate(['/landing']);
        }
      });
    }
    else if (key != undefined && key != '' && key != null && lat != undefined && lat != '' && lat != null && lng != undefined && lng != null && lng != '' && loc != undefined && loc != '' && loc != null) {
      this.landChecksService.validateVendorKey(key).subscribe(vendor => {
        if (vendor && vendor != null) {
          this.landChecksService.userAuthentication('vendor.user@mailinator.com', 'admin').subscribe((data: any) => {
            window.sessionStorage.setItem('userToken', data.access_token);
            window.sessionStorage.setItem('userId', data.userId);
            window.sessionStorage.setItem('apiKey', key);
            window.sessionStorage.setItem('vname', vendor.Name);
            window.sessionStorage.setItem('isvendor', "true");
            window.sessionStorage.setItem('vendortype', vendor.VendorType);
            
            debugger;
            if(window.location.pathname.indexOf("landing") > -1){
              window.location.href =currentUrl;
            }
            
           // this.router.navigate([currentUrl]);
          });
        }
        else {
          this.router.navigate(['/landing']);
        }
      });
    }
    else {
      if (window.sessionStorage.getItem('userToken') == null) {
        this.landChecksService.getVendorKey().subscribe(vendorData => {
          if (vendorData && vendorData.ApiKey && vendorData.ApiKey != '') {
            debugger;
            this.landChecksService.validateVendorKey(vendorData.ApiKey).subscribe(vendor => {
              if (vendor && vendor != null) {
                this.landChecksService.userAuthentication('vendor.user@mailinator.com', 'admin').subscribe((data: any) => {
                  window.sessionStorage.setItem('userToken', data.access_token);
                  window.sessionStorage.setItem('userId', data.userId);
                  window.sessionStorage.setItem('apiKey', vendorData.ApiKey);
                  window.sessionStorage.setItem('vname', vendorData.Name);
                  window.sessionStorage.setItem('vendortype', vendor.VendorType);
                  this.router.navigate(['/home']);
                });
              }
              else {
                debugger;
                this.router.navigate(['/landing']);
              }
            });
          }
          else {
            //this.router.navigate(['/landing']);
            if(window.location.pathname.indexOf("contact") > -1 || window.location.pathname.indexOf("verifylink") > -1 || window.location.pathname.indexOf("activatelink") > -1){
              this.landChecksService.userAuthentication('vendor.user@mailinator.com', 'admin').subscribe((data: any) => {
                window.sessionStorage.setItem('userToken', data.access_token);
                this.router.navigate(['currentUrl']); 
              });
            }
          }
        });
      }
      else {
          this.landChecksService.userAuthentication('vendor.user@mailinator.com', 'admin').subscribe((data: any) => {
            window.sessionStorage.setItem('userToken', data.access_token);
            this.router.navigate([currentUrl]); 
          });
      }
    }
  }

  getParamValueQueryString(paramName) {
    const url = window.location.href;
    let paramValue;
    if (url.includes('?')) {
      const httpParams = new HttpParams({ fromString: url.split('?')[1] });
      paramValue = httpParams.get(paramName);
    }
    return paramValue;
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'removeUnderscore' })
export class RemoveUnderscorePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    var str = value.split(' ').join('_'); 
    return str.replace(/[:/;]/g, "");
  }
}