import { Component, OnInit, NgZone, ViewChild, Input,Renderer2, ElementRef } from '@angular/core';
import { LandChecksService } from '../landchecks.service';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpParams } from '@angular/common/http';
import Swal from 'sweetalert2';
import { RemoveUnderscorePipe } from '../app.component';
import { Options, ChangeContext } from 'ng5-slider';
import statesData from '../../assets/json/statesdata.json';
import countiesData from '../../assets/json/countydata.json';
import citiesData from '../../assets/json/citydata.json';
import msaData from '../../assets/json/msadata.json';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  providers: [ RemoveUnderscorePipe ]
})
export class HomeComponent {
  value: number = 5;
  options: Options = {
    floor: 5,
    ceil: 5,
    step: 5,
    showTicks: true,
    showTicksValues: true
  };
  title = 'landchecks';
  address: Object;
  formattedAddress: string;
  latitude: string;
  longitude: string;
  phone: string;
  isUserLogin: boolean = false;
  @ViewChild('mymap', { static: false }) mapElement: ElementRef;
  map: google.maps.Map;
  categorySummary: any[] = [];
  categories: any[] = [];
  categoryIds: any[] = [];
  displayCategories: any = [];
  listings: any = [];
  listings1: any = [];
  listings_after: any = [];
  total_listings: any = [];
  total_listings_before_change: any = [];
  total_listings_before_delete: any = [];
  total_listings_after_delete: any = [];
  displayName: string = '';
  listings_copy: any = [];
  isExternalSearch: boolean = false;
  isFavoriteSearch: boolean = false;
  isVendorTypeOnSearch: boolean = false;
  externalLoc: string;
  vendorType: string;
  todayDate : string = new Date().toISOString();
  favoritesCount: any = 0;
  //@Input() isVendor:boolean;
  viewContent: any = [];
  noData: any = [];
  requestId: any = [];
  apikey: string =  '5C6C579C-325A-4785-8D0E-EBF3F296F53F';
  sliderRadiusValue: any = 5;
  cat_id : any = 5;
  cat_name: string = '';
  cat_names: any = [];
  private selectedLink: string="address_search";
  setradio(e: string): void   
  {  
      this.selectedLink = e;  
      //console.log(this.selectedLink);
      // if(this.selectedLink == "address_search"){
      //   if(this.latitude && this.longitude){
      //     var locations: any[] = [
      //       { 'Title': this.formattedAddress, 'Lat': this.latitude, 'Lng': this.longitude, 'Indicator': '../../assets/search_spinner/other.png' }
      //     ]
      //     this.initializeMap(true, +this.latitude, +this.longitude, locations);
      //   }else{
      //     this.initializeMap(true, 33.749, -84.38798, []);
      //   }
      // }
      console.log('areas search', this.user_state);
      this.initdashboard(this.user_state,'','','',this.total_listings_ids);


  }  
  isSelected(name: string): boolean   
  {  
    if (!this.selectedLink) { // if no radio button is selected, always return false so every nothing is shown  
      return false;  
    }  
    else{
      return (this.selectedLink === name); // if current radio button is selected, return true, else return false  
    }
  } 
  keyword = 'state_name';
  user_state:string = '';
  user_login_state: string = '';
  statesData:any = statesData;  
  
  countykeyword = 'county_name';
  countiesData:any = countiesData;
  countiesFinalData:any;
  citykeyword = 'city_name';
  citiesData:any = citiesData;
  citiesFinalData:any;
  msakeyword = 'msa_code';
  msaData:any = msaData;
  msaFinalData:any;
  dashboardId :string;
  dashboardContentObject : string;
  selectedState: string = "";
  selectedCounty: string = "";
  selectedCity: string = "";
  selectedZipCode: string = "";
  @ViewChild('stateClear') stateClear;
  @ViewChild('countyClear') countyClear;
  @ViewChild('cityClear') cityClear;
  @ViewChild('zipClear') zipClear;

  isStateSelected: boolean;
  total_listings_ids: string;
  constructor(public zone: NgZone, public landChecksService: LandChecksService, private router: Router,private route: ActivatedRoute,private renderer: Renderer2, private el: ElementRef, private  removeUnderscore: RemoveUnderscorePipe) {
    
    // if (window.sessionStorage.getItem('vname') != null && window.sessionStorage.getItem('vname') != '' &&
    //   window.sessionStorage.getItem('vname') != undefined) {
    //   this.displayName = window.sessionStorage.getItem('vname');
    // }
    // else {
    //   this.isUserLogin = true;
    //   this.displayName = window.sessionStorage.getItem('uname');
    // }
    let lat = this.getParamValueQueryString('lat');
    let lng = this.getParamValueQueryString('lng');
    let loc = this.getParamValueQueryString('loc');
    this.vendorType = window.sessionStorage.getItem('vendortype');
    if(this.user_state.toLowerCase() == 'usa'){
      this.user_state = 'usa';
    }else{
      this.user_state = window.sessionStorage.getItem('state');
      this.user_login_state = window.sessionStorage.getItem('state');
    }
    if(this.user_state){
      this.isStateSelected = true;
    }
    this.isExternalSearch = lat != undefined && lat != '' && lng != undefined && lng != '' && loc != undefined && loc != '';
    if (this.isExternalSearch) {
      this.longitude = lng;
      this.latitude = lat;
      this.externalLoc = loc;
    }
    else if (window.sessionStorage.getItem('loc') != null && window.sessionStorage.getItem('loc') != '' &&
      window.sessionStorage.getItem('loc') != undefined) {
      this.isExternalSearch = true;
      debugger;
      this.longitude = window.sessionStorage.getItem('lng');
      this.latitude = window.sessionStorage.getItem('lat');
      this.externalLoc = window.sessionStorage.getItem('loc');
    }
    else if (window.sessionStorage.getItem('location') != null && window.sessionStorage.getItem('location') != '' &&
      window.sessionStorage.getItem('location') != undefined) {
      this.longitude = window.sessionStorage.getItem('lng');
      this.latitude = window.sessionStorage.getItem('lat');
      this.externalLoc = window.sessionStorage.getItem('location');
      this.isFavoriteSearch = true;
      this.isExternalSearch = true;
    }
    if(this.isFavoriteSearch){
      window.sessionStorage.removeItem('location');
      window.sessionStorage.removeItem('lng');
      window.sessionStorage.removeItem('lat');
      this.isExternalSearch = false;
      this.isFavoriteSearch = true;
    }
    //document.getElementById("autocompleteForm").autocomplete = "off";

  }
  
  onSubmit(id) {
    this.dashboardId = id;
    this.initdashboard(this.user_state, this.selectedCounty, this.selectedCity, this.selectedZipCode, this.total_listings_ids);
 }

  ngOnInit(): void {
    // get the master categories from API
    this.landChecksService.getCategories().subscribe(result => {
      this.categories = result;
      this.categories.forEach(element => {
        element.IsSelected = false;
        element.Indicator = `../../assets/search_spinner/${element.Indicator}`;
      });
    })
    debugger;
    this.initdashboard('','','','',this.total_listings_ids);
  }
  
  initdashboard(state, county, city, zipcode, listingId) {   
    // this.route.params.subscribe(params => {
    //   this.dashboardId = params.dashboardId;
    // });
    var viveService = {
      content:'',
      initiate: function () {
          return this;
      },
      generate: function () {
          var host = (window.location.host == "") ? "null" : window.location.host;
          var filters = (this.filters == "") ? "false" : encodeURIComponent(JSON.stringify(this.filters));
          var html = '<object data="' + this.URL + '&header=' + this.header + '&requestfrom=' + host + '&filters=' + filters + '" style="border: 0;height:100%;width:100%"></object>';
          return(this.content=html);
      }
    }
    var service = viveService.initiate();
      //Embedded Link
    if(this.selectedLink == 'address_search'){
      service.URL="https://data.landchecks.com/ViveBI/DynamicDashboard/Embed/EmbedView.aspx?id=MTAxNiNAI0AjYWRtaW4=";
    }
    else{
      if(this.dashboardId){
        service.URL="https://data.landchecks.com/ViveBI/DynamicDashboard/Embed/EmbedView.aspx?id="+this.dashboardId;
      }else{
        service.URL="https://data.landchecks.com/ViveBI/DynamicDashboard/Embed/EmbedView.aspx?id=NiNAI0AjYWRtaW4=";
      }
    }
    // The filter paramerters/attributes should not contain any special characters and space between words.
    console.log('filteers', state);
    console.log('filterstae', county);
    //if(!state && (!state && !county)){
    if(this.selectedLink == 'address_search'){
      console.log('in if');
      //console.log(listingId);
      if (!(listingId)){
        listingId='0';  // set it to 0 to not to show any data in dashboard for address_search
      }
      if (!this.user_state){
        this.user_state ="usa";
      }
      service.filters = {
        filters: {
          "StateName": this.user_state.toLowerCase() == 'usa' ? [] : [this.user_state],
          "ListingID": listingId ? [listingId] : []
        },
        customSecurity: {
          "StateName": this.user_state.toLowerCase() == 'usa' ? [] : [this.user_state],
        }
      }
      console.log(service.filters);
    }
    else{
      console.log('in else');
      service.filters = {
        filters: {
          "StateName": state.toLowerCase() == 'usa' ? [] : [state],
          "CountyName": county ? [county] : [],
          "CityName": city ? [city] : [],
          "ZipCode": zipcode ? [zipcode] : [],
          "ListingID": [],
          //"ListingID":  ["4699225"],
          
        },
        customSecurity: {
          "StateName": state.toLowerCase() == 'usa' ? [] : [state],
          //"CountyName": county ? [county] : "",
          //"CityName": city ? [city] : "",
          //"ZipCode": zipcode ? [zipcode] : "",
          //"ListingID": listingId ? [listingId] : ""
        }
      }
    }
    service.content = '';
    service.header = false;
    service.generate(this.dashboardContentObject);
    this.dashboardContentObject = viveService.content;
  }
  ngAfterViewInit() {
    if (this.isExternalSearch || this.isFavoriteSearch) {
      var locations: any[] = [
        { 'Title': this.externalLoc, 'Lat': +this.latitude, 'Lng': +this.longitude, 'Indicator': '../../assets/search_spinner/other.png' }
      ]
      this.initializeMap(true, +this.latitude, +this.longitude, locations);
      this.getCategorySummary();     
    }
    else {
      this.initializeMap(false, 33.749, -84.38798, []);
    }
  }
  categorySearch(){
    this.isFavoriteSearch = false;
    this.isVendorTypeOnSearch = true;
  }
  keyDownFunction(event) {
    if(event.keyCode == 13) {
      this.getCategorySummary(); 
      this.categorySearch();
      // rest of your code
    }
  }
  onUserChange(changeContext: ChangeContext): void {
    this.sliderRadiusValue = changeContext.value;
    window.sessionStorage.setItem('radius', this.sliderRadiusValue);
    var radius = window.sessionStorage.getItem('radius');
    if(this.total_listings_after_delete.length > 0){
      var lists: any[] = [];
      var listing_total: any[] = [];
        this.cat_names.forEach(name => {
          var lists1 = this.total_listings.filter(x => x.Category == name);
          lists.push(lists1);
      });         
      lists.forEach(listing => {
        listing.forEach(listing1 => {
          listing_total.push(listing1);
        });
      });
      this.total_listings = listing_total;
    }
    if(radius){
      this.listings = this.total_listings.filter(x => (x.dist <= radius) || (x.dist == "test") );
    }else{
      this.listings = this.total_listings.filter(x => (x.dist <= 5) || (x.dist == "test") );
    }
    this.listings = this.listingsFilters(this.listings);
    var locations: any[] = [];
    var listings_ids: any[] = [];
    console.log(this.listings.length);
    this.listings.forEach(element => {
      // if (element.Indicator == undefined || element.Indicator == '') {
      //   element.Indicator = category.Indicator;
      // }
      if(element.ListingId){
        listings_ids.push(element.ListingId);
      }
      if(element.Category.toLowerCase() == 'zoning') {
    
        this.total_listings_ids = '"' + listings_ids.join("\",\"") + '"';
        this.total_listings_ids =  listings_ids.slice(0,51).join("\",\"") ;
      }
      else{
        this.total_listings_ids = '0';
      }
      locations.push({ 'ListingSequence': element.ListingSequence, 'ListingId': element.ListingId,'Title': element.Title, "ShortDesc": element.ShortDescription, 'Lat': +element.latitude, 'Lng': +element.longitude, 'Indicator': element.Indicator });
    });
    console.log(locations);
    this.initializeMap(true, +this.latitude, +this.longitude, locations);
    return;
  }
  listingsFilters(listings){
    var categories: any[] = [];
    listings.forEach(element => {
      categories.push(element.Category);
    })
    const count = categories =>
    categories.reduce((a, b) => ({ ...a,
        [b]: (a[b] || 0) + 1
      }), {})
    const duplicates = dict =>
      Object.keys(dict).filter((a) => dict[a] > 1)
    for (const property in count(categories)) {
      var nu = `${count(categories)[property]}`;
      if(parseInt(nu) <= 1){
        this.noData = {Category: `${property}`, noData: 'No data available'};
        var index = this.func2(listings, `${property}`);
        listings.splice(index + 1, 0, this.noData); 
      }
    }
    return listings;
  }
  initializeMap(setmarker: boolean, lat: any, lng: any, locations: any[]) {
    console.log('in map');
    console.log(locations);
    var center = { lat: lat, lng: lng };
    console.log(lat, lng);
    const mapProperties = {
      center: center,
      zoom: 11,
      mapTypeId: google.maps.MapTypeId.ROADMAP
    };

    this.map = new google.maps.Map(this.mapElement.nativeElement, mapProperties);
    // show selected location.
    var markerHome = new google.maps.Marker({
      position: center,
      map: this.map
    });
    // Add circle overlay and bind to marker
    var circle5 = new google.maps.Circle({
      map: this.map,
      radius: 8087,    // 5 miles in metres
      fillColor: '#AA0000',
      fillOpacity: 0.0,
      strokeColor:'#AA0000'
    });
    
    // add title for 5 miles
    circle5.addListener('mouseover',function(){
      this.getMap().getDiv().setAttribute('title','5 miles');
    });

    circle5.addListener('mouseout',function(){
      this.getMap().getDiv().removeAttribute('title');
    });
    circle5.bindTo('center', markerHome, 'position');
    

    // Add circle overlay and bind to marker
    var circle3 = new google.maps.Circle({
      map: this.map,
      radius: 4852,    // 3 miles in metres
      fillColor: '#A8BB00',
      fillOpacity: 0.0,
      strokeColor: '#008000'
    });

    // add title for 3 miles
    circle3.addListener('mouseover',function(){
      this.getMap().getDiv().setAttribute('title','3 miles');
    });

    circle3.addListener('mouseout',function(){
      this.getMap().getDiv().removeAttribute('title');
    });
    
    circle3.bindTo('center', markerHome, 'position');

    // Add circle overlay and bind to marker
    var circle1 = new google.maps.Circle({
      map: this.map,
      radius: 1617 ,    // 1 miles in metres
      fillColor: '#80BFD6',
      fillOpacity: 0.0,
      strokeColor:'#0000FF'
    });

    // add title for 1 mile
    circle1.addListener('mouseover',function(){
      this.getMap().getDiv().setAttribute('title','1 mile');
    });

    circle1.addListener('mouseout',function(){
      this.getMap().getDiv().removeAttribute('title');
    });
    
    circle1.bindTo('center', markerHome, 'position');

    if (setmarker) {
      var infowindow = new google.maps.InfoWindow({});
      locations.forEach(x => {
        var center = { lat: x.Lat, lng: x.Lng };
        var marker = new google.maps.Marker({
          position: center,
          map: this.map,
          icon: x.Indicator
        });
        google.maps.event.addListener(marker, 'click', (function (marker) {
          return function () {
            let title = `<strong>#${x.ListingSequence}</strong><br/><a id="listing_id" >${x.Title}</a> <br/> <br/> ${x.ShortDesc}`;
              infowindow.setContent(title);
              infowindow.open(this.map, marker);
              infowindow.addListener('domready', () => {
                document.getElementById("listing_id").addEventListener("click", () => {
                  //var elmnt = document.getElementById("listing_id").innerHTML;
                  const element = document.querySelector('#'+'a_'+x.ListingId);
                  element.classList.add("selected-listing");
                  document.querySelector('#'+'a_'+x.ListingId).scrollIntoView({behavior: 'smooth'});
                  
                });
                
              });
          }
          
        })(marker));
      })
    }
  }
  getAddress(place: any) {
    this.latitude = place.geometry.location.lat();
    this.longitude = place.geometry.location.lng();
    this.address = place['formatted_address'];
    this.phone = this.getPhone(place);
    this.formattedAddress = place['formatted_address'];
    this.listings = [];
    this.displayCategories = [];
    this.listings_copy = [];
    var locations: any[] = [
      { 'Title': this.formattedAddress, 'Lat': this.latitude, 'Lng': this.longitude, 'Indicator': '../../assets/search_spinner/other.png' }
    ]
    this.initializeMap(true, this.latitude, this.longitude, locations);
  }



  getAddrComponent(place, componentTemplate) {
    let result;
    for (let i = 0; i < place.address_components.length; i++) {
      const addressType = place.address_components[i].types[0];
      if (componentTemplate[addressType]) {
        result = place.address_components[i][componentTemplate[addressType]];
        return result;
      }
    }
    return;
  }

  getStreetNumber(place) {
    const COMPONENT_TEMPLATE = { street_number: 'short_name' },
      streetNumber = this.getAddrComponent(place, COMPONENT_TEMPLATE);
    return streetNumber;
  }

  getStreet(place) {
    const COMPONENT_TEMPLATE = { route: 'long_name' },
      street = this.getAddrComponent(place, COMPONENT_TEMPLATE);
    return street;
  }

  getCity(place) {
    const COMPONENT_TEMPLATE = { locality: 'long_name' },
      city = this.getAddrComponent(place, COMPONENT_TEMPLATE);
    return city;
  }

  getState(place) {
    const COMPONENT_TEMPLATE = { administrative_area_level_1: 'short_name' },
      state = this.getAddrComponent(place, COMPONENT_TEMPLATE);
    return state;
  }

  getDistrict(place) {
    const COMPONENT_TEMPLATE = { administrative_area_level_2: 'short_name' },
      state = this.getAddrComponent(place, COMPONENT_TEMPLATE);
    return state;
  }

  getCountryShort(place) {
    const COMPONENT_TEMPLATE = { country: 'short_name' },
      countryShort = this.getAddrComponent(place, COMPONENT_TEMPLATE);
    return countryShort;
  }

  getCountry(place) {
    const COMPONENT_TEMPLATE = { country: 'long_name' },
      country = this.getAddrComponent(place, COMPONENT_TEMPLATE);
    return country;
  }

  getPostCode(place) {
    const COMPONENT_TEMPLATE = { postal_code: 'long_name' },
      postCode = this.getAddrComponent(place, COMPONENT_TEMPLATE);
    return postCode;
  }

  getPhone(place) {
    const COMPONENT_TEMPLATE = { formatted_phone_number: 'formatted_phone_number' },
      phone = this.getAddrComponent(place, COMPONENT_TEMPLATE);
    return phone;
  }

  saveFavorites() {
    if(this.longitude != undefined && this.longitude != '' && this.latitude != undefined && this.latitude != ''){
      this.landChecksService.showLoader(true);
      let data = {
        Id: 0,
        UserId: window.sessionStorage.getItem('userId'),
        Longitude: this.longitude,
        Latitude: this.latitude,
        Description: this.formattedAddress,
        Type: 0,
        CreatedDate: this.todayDate,
        UpdatedDate: this.todayDate
        //ApiKey: window.sessionStorage.getItem('apiKey')
      };
      this.landChecksService.getFavorites(window.sessionStorage.getItem('userId')).subscribe(result => {
        this.favoritesCount = result.length;
        if(this.favoritesCount < 5){
          this.landChecksService.insertFavorites(data).subscribe(result => {
            this.landChecksService.showLoader(false);
            Swal.fire({
              icon: 'success',
              text: 'Your search address has been added to favorite.'
            })
            this.landChecksService.showLoader(false);
          });
        }else{
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: "You can't add morethan 5 favorites. If you wanna add this location as favorite please remove atleast one location from your favorites list.",
            footer: '<a href="/myaccount">You can remove here</a>'
          })
        }
        this.landChecksService.showLoader(false);
      })
      
    }else{
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Please search location before you want to make it favorite.'
      })
    }
  }

  getCategorySummary() {
    this.landChecksService.showLoader(true);
    this.total_listings = [];
    window.sessionStorage.setItem('radius', '5');
    this.value = 5;
    let data =
    {
      Longitude: this.longitude,
      Latitude: this.latitude
    };
    if(data.Longitude == undefined && data.Latitude == undefined){
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Please enter location to search'
      })
      this.landChecksService.showLoader(false);
    }else{
      if (this.externalLoc != null && this.externalLoc != undefined && this.externalLoc != ''){
        this.retrieveCategorySummary(data);
      }
      else{
        this.retrieveCategorySummary(data);
        this.getRequestId();
      }
      
    }
  }
  retrieveCategorySummary(data: any){
    this.landChecksService.getCategorySummary(data).subscribe(result => {
      // Save user transaction here
      let transaction = {
        Id: 0,
        UserId: window.sessionStorage.getItem('userId'),
        Longitude: data.Longitude,
        Latitude: data.Latitude,
        Address: this.formattedAddress,
        CategoryCount: 0,
        CategoryMetaData: JSON.stringify(result),
        ApiKey: window.sessionStorage.getItem('apiKey')
      };
      this.insertTransaction(transaction);
      if (result && result.categories && result.categories.length > 0) {
        this.displayCategories = [];
        this.listings = [];
        result.categories.forEach(category => {
          let c = this.categories.find(x => x.Name.toLowerCase() == category.categoryName.toLowerCase())
          if (c) {
            c.Count = category.count;
            this.displayCategories.push(c);
          }
        });
      }
      this.landChecksService.showLoader(false);
    });
  }
  getCategoryListing(category: any) {
    //console.log('in getCategoryListing');
    this.cat_id = category.Id;
    this.cat_name = category.Name;
    this.cat_names.push(this.cat_name);
    let data =
    {
      CategoryId: category.Id,
      Longitude: this.longitude,
      Latitude: this.latitude
    };

    if (!category.IsSelected && this.listings.length > 0) {
      console.log('in first of');
      var name = this.cat_name;
      this.cat_names = this.cat_names.filter(function(item) {
        return item !== name
      })
      this.categoryIds = this.categoryIds.filter(x => x !== category.Id);
      this.listings = this.listings.filter(x => (x.Category != category.Name));
      
      this.total_listings_after_delete = this.listings;
      this.total_listings_before_delete = this.total_listings;
      var locations: any[] = [];
      var listings_ids: any[] = [];
      if(this.listings.length > 0){
        this.listings.forEach(element => {
          if(element.ListingId){
            listings_ids.push(element.ListingId);
          }
          // if(element.Category.toLowerCase() == 'zoning'){
          //   this.total_listings_ids = '"' + listings_ids.join("\",\"") + '"';
          // }
          locations.push({'ListingSequence': element.ListingSequence, 'ListingId': element.ListingId, 'Title': element.Title, "ShortDesc": element.ShortDescription, 'Lat': +element.latitude, 'Lng': +element.longitude, 'Indicator': element.Indicator });
        });
        this.total_listings_ids = '';
        console.log('in delete if');
        this.initdashboard(this.user_state,'','','',this.total_listings_ids);
      }else{
        console.log('in delete else');
        this.total_listings_ids = '';
        this.initdashboard(this.user_state,'','','','');
      }
      this.initializeMap(true, +this.latitude, +this.longitude, locations);
      return;
    }
    if (this.listings_copy.length > 0) {
      console.log('in second of');
      // if listing is available in master variable
      this.getCategoryListingReport(category.Id);
      var hasListings = this.listings_copy.filter(x => x.Category.toLowerCase() == category.Name.toLowerCase());
      if (hasListings.length > 0) {
        this.listings.push(...hasListings);
        var radius = window.sessionStorage.getItem('radius');
        if(radius){
          this.listings = this.listings.filter(x => (x.dist <= radius) || (x.dist == "test"));
        }else{
          this.listings = this.listings.filter(x => (x.dist <= 5) || (x.dist == "test"));
        }
        this.listings = this.listingsFilters(this.listings);

        var locations: any[] = [];
        var listings_ids: any[] = [];
        this.total_listings = this.listings_copy;
        this.listings.forEach(element => {
          if(element.ListingId){
            listings_ids.push(element.ListingId);
          }
          if(element.Category.toLowerCase() == 'zoning'){
            this.total_listings_ids = '"' + listings_ids.join("\",\"") + '"';
            this.total_listings_ids = listings_ids.slice(0,51).join("\",\"") ;
          }else{
            this.total_listings_ids = '0';
          }
          if (element.Indicator == undefined || element.Indicator == '') {
            element.Indicator = category.Indicator;
          }
          locations.push({ 'ListingSequence': element.ListingSequence, 'ListingId': element.ListingId,'Title': element.Title, "ShortDesc": element.ShortDescription, 'Lat': +element.latitude, 'Lng': +element.longitude, 'Indicator': element.Indicator });
        });
        if(this.total_listings_ids){
          console.log('in second if');
          this.initdashboard(this.user_state,'','','',this.total_listings_ids);
        }else{
          console.log('in second else');
          this.initdashboard(this.user_state,'','','','');
        }
        this.initializeMap(true, +this.latitude, +this.longitude, locations);
        return;
      }
    }
    this.landChecksService.showLoader(true);
    console.log('before get ctg listing');
    
    console.log(data);
    console.log('data');
    this.landChecksService.getCategoryListing(data).subscribe(result => {
      // Save user transaction here
      let transaction = {
        Id: 0,
        UserId: window.sessionStorage.getItem('userId'),
        Longitude: data.Longitude,
        Latitude: data.Latitude,
        Address: this.formattedAddress,
        CategoryCount: result && result.listings && result.listings.length > 0 ? result.listings.length : 0,
        CategoryMetaData: JSON.stringify(result),
        ApiKey: window.sessionStorage.getItem('apiKey')
      };
      this.insertTransaction(transaction);
      console.log(result);
      
      if (result && result.listings && result.listings.length > 0) {
        console.log('in third normal of');
        if(this.categoryIds.length < 1){
          this.getCategoryListingReport(category.Id);
        }
        this.viewContent = {Category: category.Name, categoryName: category.Name, htmlContent : 'Download PDF', categoryId: data.CategoryId, dist:'test'};
        this.listings_after.push( this.viewContent, ...result.listings);
        this.listings_copy.push( this.viewContent, ...result.listings);
        var radius = window.sessionStorage.getItem('radius');
        if(this.total_listings_after_delete){
          this.listings.push( this.viewContent, ...result.listings);
          if(radius){
            this.listings = this.listings.filter(x => (x.dist <= radius) || (x.dist == "test"));
          }else{
            this.listings = this.listings.filter(x => (x.dist <= 5) || (x.dist == "test"));
          }
          this.total_listings = this.listings_copy;
        }else{
          this.listings1.push( this.viewContent, ...result.listings);
          this.total_listings = this.listings1;
          this.listings.push(this.listings1);
          if(radius){
            this.listings = this.total_listings.filter(x => (x.dist <= radius) || (x.dist == "test"));
          }else{
            this.listings = this.total_listings.filter(x => (x.dist <= 5) || (x.dist == "test"));
          }
        }
        this.listings = this.listingsFilters(this.listings);
        var locations: any[] = [];
        var listings_ids: any[] = [];
        this.listings.forEach(element => {
          if(element.ListingId){
            listings_ids.push(element.ListingId);
          }
          if(element.Category.toLowerCase() == 'zoning'){
            this.total_listings_ids = '"' + listings_ids.join("\",\"") + '"';
            this.total_listings_ids =  listings_ids.slice(0,51).join("\",\"") ;
          }else{
            this.total_listings_ids = '0';
          }
          if (element.Indicator == undefined || element.Indicator == '') {
            element.Indicator = category.Indicator;
          }
          locations.push({ 'ListingSequence': element.ListingSequence, 'ListingId': element.ListingId,'Title': element.Title, "ShortDesc": element.ShortDescription, 'Lat': +element.latitude, 'Lng': +element.longitude, 'Indicator': element.Indicator });
          console.log(locations);
          
        });
        this.initializeMap(true, +this.latitude, +this.longitude, locations);
        if(this.total_listings_ids){
          this.initdashboard(this.user_state,'','','',this.total_listings_ids);
        }else{
          this.initdashboard(this.user_state,'','','','');
        }
      }
      //console.log(this.total_listings_ids);
      this.landChecksService.showLoader(false);
    });
  }
  func2(array, attr){
    for (var i = 0; i < array.length; i += 1) {
      if (array[i]['Category'] === attr) { 
        return i; 
      } 
    }  
    return -1; 
  }
  insertTransaction(transactionData: any) {
    this.landChecksService.insertTransaction(transactionData).subscribe(result => { })
  }

  terminateSession() {
    window.sessionStorage.clear();
    debugger;
    this.router.navigate(['/landing']);
  }

  resetPassword() {
    this.router.navigate(['/resetpassword']);
  }

  getParamValueQueryString(paramName) {
    const url = window.location.href;
    let paramValue;
    if (url.includes('?')) {
      const httpParams = new HttpParams({ fromString: url.split('?')[1] });
      paramValue = httpParams.get(paramName);
    }
    return paramValue;
  }
  getRequestId(){
    window.sessionStorage.setItem('apikey', this.apikey);
    let data =
    {
      apiKey: window.sessionStorage.getItem('apikey'),
      lat : this.latitude,
      lng : this.longitude,
      address : this.formattedAddress
    };
    this.landChecksService.getRequestId(data).subscribe(result => {
      this.requestId = result;
      window.sessionStorage.setItem('requestID', this.requestId);
    })
  }
  loadReport() {
    if (this.externalLoc != null && this.externalLoc != undefined && this.externalLoc != '')
    {
      if(window.sessionStorage.getItem('requestID')){
        var propertyid =  window.sessionStorage.getItem('requestID');
      }else{
        var propertyid = window.sessionStorage.getItem('vrequestID');
      }
      window.open("https://report.landchecks.com/GenReport.aspx?propertyid="+propertyid+"&type=0", "_blank");
    }else{
      if(this.longitude != undefined && this.longitude != '' && this.latitude != undefined && this.latitude != ''){
        var propertyid =  window.sessionStorage.getItem('requestID');
        window.open("https://report.landchecks.com/GenReport.aspx?propertyid="+propertyid+"&type=0", "_blank");
      }else{
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Please search a location to print a report.'
        })
      }
    }
  }
  getCategorySummaryReport(){
    
    if (this.externalLoc != null && this.externalLoc != undefined && this.externalLoc != '')
    {
      if(window.sessionStorage.getItem('requestID')){
        var propertyid =  window.sessionStorage.getItem('requestID');
      }else{
        var propertyid = window.sessionStorage.getItem('vrequestID');
      }
    }else{
      var propertyid = window.sessionStorage.getItem('requestID');
    }
    window.open("https://report.landchecks.com/GenReport.aspx?propertyid="+propertyid+"&type=2", "_blank");
  }
  getEachCategoryListingReport(categoryId){
    if (this.externalLoc != null && this.externalLoc != undefined && this.externalLoc != '')
    {
      if(window.sessionStorage.getItem('requestID')){
        var propertyid =  window.sessionStorage.getItem('requestID');
      }else{
        var propertyid = window.sessionStorage.getItem('vrequestID');
      }
    }else{
      var propertyid = window.sessionStorage.getItem('requestID');
    }
    window.open("https://report.landchecks.com/GenReport.aspx?propertyid="+propertyid+"&type=0&catID="+categoryId, "_blank");
  }
  getCategoryListingReport(categoryId){
    this.categoryIds.push(categoryId);
  }
  getCategoryListingReportGen(){
    if (this.externalLoc != null && this.externalLoc != undefined && this.externalLoc != '')
      {
        if(window.sessionStorage.getItem('requestID')){
          var propertyid =  window.sessionStorage.getItem('requestID');
        }else{
          var propertyid = window.sessionStorage.getItem('vrequestID');
        }
      }else{
        var propertyid = window.sessionStorage.getItem('requestID');
      }
      window.open("https://report.landchecks.com/GenReport.aspx?propertyid="+propertyid+"&type=0&catID="+this.categoryIds, "_blank");
  }
  selectStateEvent(item) {
    if(this.selectedState){
      this.isStateSelected = true;
    }
    this.selectedState = item.state_name;
    console.log(this.selectedState);
    var countiesFilterData: any[] = [];
    
    var newArray = this.countiesData.filter(function (county_name) {
      if(county_name.county_state_name.toLowerCase() == item.state_name.toLowerCase()){
        countiesFilterData.push({'county_name': county_name.county_name, 'county_state_name':county_name.county_state_name});
      }
    });
    // console.log('before', this.user_state);
    // this.user_state = this.selectedState;
    // console.log('after', this.user_state);
    this.countiesFinalData = countiesFilterData;
    //this.initdashboard(this.user_state, '', '', '', this.total_listings_ids);
  }
  onFocusedCounties(){
    //this.selectedState = this.user_state;
    let state = '';
    if(this.user_login_state.toLowerCase() != 'usa'){
      console.log('in if');
      state = this.user_state;
    }else{
      console.log('in else');
      state = this.selectedState;
    }
    console.log('focus', state);
    //let state = this.user_state;
    var countiesFilterData: any[] = [];
    var newArray = this.countiesData.filter(function (county_name) {
      if(county_name.county_state_name.toLowerCase() == state.toLowerCase()){
        countiesFilterData.push({'county_name': county_name.county_name, 'county_state_name':county_name.county_state_name});
      }
    });
    this.countiesFinalData = countiesFilterData;
  }
  selectCountyEvent(item) {
    this.selectedCounty = item.county_name;
    var citiesFilterData: any[] = [];
    var newArray1 = this.citiesData.filter(function (city_name) {
      if(city_name.city_county.toLowerCase() == item.county_name.toLowerCase()){
        citiesFilterData.push({'city_name': city_name.city_name, 'city_county': item.county_name, 'city_state_name':item.county_state_name});
      }
    });
    this.citiesFinalData = citiesFilterData;
  }
  selectCityEvent(item) {
    this.selectedCity = item.city_name;
    var msaFilterData: any[] = [];
    var newArray1 = this.msaData.filter(function (msa_code) {
      if(msa_code.msa_state_name.toLowerCase() == item.city_state_name.toLowerCase()){
        msaFilterData.push({'msa_code': msa_code.msa_code, 'msa_title': msa_code.msa_title, 'msa_state_name':item.city_state_name});
      }
    });
    this.msaFinalData = msaFilterData;
  }
  selectZipCode(item){
    this.selectedZipCode = item.msa_code;
  }
  applyFiltersOnDashboard(){
    console.log('apply', this.selectedState);
    console.log('apply', this.selectedCounty);
    this.initdashboard(this.selectedState, this.selectedCounty, this.selectedCity, this.selectedZipCode, this.total_listings_ids);
  }
  clearFiltersOnDashboard(){
    if(this.user_login_state.toLowerCase() == 'usa'){
      this.stateClear.clear();
      this.stateClear.close();
    }
    this.selectedState = '';
    this.selectedCounty = '';
    this.selectedCity = '';
    this.selectedZipCode = '';
    
    this.countyClear.clear();
    this.cityClear.clear();
    this.zipClear.clear();
    
    this.countyClear.close();
    this.cityClear.close();
    this.zipClear.close();
    this.initdashboard(this.user_state, '', '', '', this.total_listings_ids);
  }
}