import { Component, OnInit } from '@angular/core';
import { LandChecksService } from '../landchecks.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-userportfolio',
  templateUrl: './userportfolio.component.html',
  styleUrls: ['./userportfolio.component.scss']
})
export class UserportfolioComponent implements OnInit {
  displayName: string = '';
  userId: string = '';
  favorites: any[] = [];
  favoritesCount: any = 0;
  todayDate : Date = new Date();

  constructor(private landChecksService: LandChecksService, private router: Router) { 
    if (window.sessionStorage.getItem('uname') != null && window.sessionStorage.getItem('uname') != '' &&
      window.sessionStorage.getItem('uname') != undefined) {
      this.displayName = window.sessionStorage.getItem('uname');
      this.userId = window.sessionStorage.getItem('userId');
    }
  }

  ngOnInit() {
    // get the favorites from API
    this.landChecksService.showLoader(true);
    this.landChecksService.getFavorites(this.userId).subscribe(result => {
      this.favorites = result;
      this.favoritesCount = this.favorites.length;
      this.landChecksService.showLoader(false);
    })
  }
  getFavorites() {
    this.landChecksService.getFavorites(this.userId).subscribe(result => {
      this.favorites = result;
      this.favoritesCount = this.favorites.length;
    })
  }
  deleteFavorite(favId) {
    Swal.fire({
      title: 'Are you sure?',
      text: "You want to remove this favorite",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, remove it!'
    }).then((result) => {
      if (result.value) {
        this.landChecksService.showLoader(true);
      let data = {
        favId: favId,
        UserId: this.userId,
      };
      this.landChecksService.removeFavorite(data).subscribe(result => {
        if(result){
          this.getFavorites();
          Swal.fire(
            'Your favorite has been removed'
          )
        }else{
          
        }
        this.landChecksService.showLoader(false);
      });
      }
    })
  }
  viewCategories(Longitude, Latitude, Location){
    window.sessionStorage.setItem('lng', Longitude);
    window.sessionStorage.setItem('lat', Latitude);
    window.sessionStorage.setItem('location', Location);
    this.router.navigate(['/home']);
  }
}